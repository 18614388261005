import React from 'react'
import  "./orderOnline.css"


function orderOnline() {
    return (
        <div className="wash-fold-section">
                <div className="container-fluid">
                    <div className="row justify-content-center" >
                        <div className="col-lg-6">
                            <div className="wash-fold-card">
                                <div className="wash-fold-text">
                                    <h2>WASH <br />& <br/> FOLD</h2>
                                </div>
                                <div className="wash-fold-info">
                                    <ul>
                                        <li><i className="fa fa-check"></i> <p>Washed under <span>30 degrees</span></p></li>
                                        <li><i className="fa fa-check"></i> <p>Dried at <span>medium heat</span></p></li>
                                        <li><i className="fa fa-check"></i> <p>Customer’s responsibility to <span>make sure all items are washable</span></p></li>
                                        <li><i className="fa fa-check"></i><span> <p><span>Towels and beddings</span> are  excluded and will be charged separately.</p></span></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
        </div>
    )
}

export default orderOnline