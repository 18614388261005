import React, { useEffect, useState } from "react";
import { NavLink,Link } from 'react-router-dom';
import { service1,service2,service3,service4,service5,service6,service7} from '../../assets/img'
function Ourservices() {
  return (
    <div className="our-service-section">
        <div className="container">
            <div className="row">
                <div className="col-lg-12">
                    <div className="section-title text-center aos-init mb-5" data-aos="zoom-in-up">
                        <h1 className="mb-2">Our Services</h1>
                    </div>
                </div>
            </div>
            <div className="our-service-inner aos-init" data-aos="zoom-in-up">
                
                <div className="our-service-wrapper">
                    <div className="row">
                        <div className="col-md-4 d-flex col-sm-6">
                            <div className="service-widget flex-fill">
                                <div className="services-img">
                                    <img src={service1} alt="" className="img-fluid" />
                                </div>
                                <h4>Dry Cleaning</h4>
                                <p>Shirts, Jackets, Dresses, Suits etc Items are delivered on hangers</p>
                                <span>FROM £5.50</span>                               
                            </div>
                        </div>
                        <div className="col-md-4 d-flex col-sm-6">
                            <div className="service-widget flex-fill">
                                <div className="services-img">
                                    <img src={service1} alt="" className="img-fluid" />
                                </div>
                                <h4>Alterations</h4>
                                <p>Zip repairs, shortening, lengthening, tapering etc</p> 
                                <span>FROM £4.00</span>                               
                            </div>
                        </div>
                        <div className="col-md-4 d-flex col-sm-6">
                            <div className="service-widget flex-fill">
                                <div className="services-img">
                                    <img src={service1} alt="" className="img-fluid" />
                                </div>
                                <h4>Shirt Service</h4>
                                <p>Washed at 30°C or 45°C, Tumble dried, pressed and hanged or folded if requested.</p>                                
                                <span>FROM £4.00</span>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-4 d-flex col-sm-6">
                            <div className="service-widget flex-fill">
                                <div className="services-img">
                                    <img src={service1} alt="" className="img-fluid" />
                                </div>
                                <h4>Ironing</h4>
                                <p>Items are delivered on hangers or folded if requested</p>   
                                <span>FROM £3.00</span>                             
                            </div>
                        </div>
                        <div className="col-md-4 d-flex col-sm-6">
                            <div className="service-widget flex-fill">
                                <div className="services-img">
                                    <img src={service1} alt="" className="img-fluid" />
                                </div>
                                <h4>Service Wash</h4>
                                <p>Washed at 30°C or 45°C, Tumble dried and Folded</p>         
                                <span>FROM £5.50</span>                       
                            </div>
                        </div>
                        <div className="col-md-4 d-flex col-sm-6">
                            <div className="service-widget flex-fill">
                                <div className="services-img">
                                    <img src={service1} alt="" className="img-fluid" />
                                </div>
                                <h4>Beddings</h4>
                                <p>Washed dried and pressed. Larger items may require different cleaning method</p>      
                                <span>FROM £3.00</span>                          
                            </div>
                        </div>
                    </div>
                    <div className="view-price">
                        <NavLink to={'./pricing'} className="btn btn-primary hover-btn">See Full Price List</NavLink>
                    </div>
                </div>
            </div>
        </div>
    </div>
  );
}

export default Ourservices;
